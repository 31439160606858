<div class="d-flex">
  <div class="qr">
    <div class="hint">
      Scan QR code to play with a mobile phone
    </div>
    <img atl="QR Code to play on mobile" src="https://res.cloudinary.com/dvujyxh7e/image/upload/v1595380432/angular-tetris-qr-code-no-text.png" />
  </div>
  <div class="tweet-button top">
    <a t-shared-button [href]="tweetMaxScoreShareUrl()" (click)="sendTwitterShareMaxScoreEvent()">
      Share Your Score - {{ max() }}
    </a>
  </div>
  <div class="tweet-button" title="This is one of my favorite project!">
    <a t-shared-button [showIcon]="false" [href]="'https://github.com/trungk18/angular-spotify'">
      🎧 Angular Spotify
    </a>
  </div>
  <div class="tweet-button" title="This is one of my favorite project!">
    <a t-shared-button [showIcon]="false" [href]="'https://jira.trungk18.com/'">
      🔨 Angular Jira Clone
    </a>
  </div>
  <div class="tweet-button" title="Thanks for your support!">
    <a t-shared-button [showIcon]="false" [href]="'https://www.buymeacoffee.com/trungvose'">
      🍺 Buy me a beer
    </a>
  </div>
  <div class="tweet-button" style="margin-top: 2px;">
    <a t-shared-button [href]="tweetAngularTetrisUrl" (click)="sendTwitterShareEvent()">
      Tweet
    </a>
  </div>
  <div class="star-button">
    <iframe src="https://ghbtns.com/github-btn.html?user=trungk18&repo=angular-tetris&type=star&count=true&size=large" frameborder="0" scrolling="0" width="170" height="30" title="Angular Tetris Star">
    </iframe>
  </div>
</div>