<p>
    <span *ngIf="hasCurrent() else startingTmpl">
        Lines
    </span>
</p>
<t-number *ngIf="hasCurrent() else initLineTmpl"
          [num]="clearedLines()">
</t-number>
<ng-template #startingTmpl>
    Start Line
</ng-template>
<ng-template #initLineTmpl>
    <t-number [num]="initLine()">
    </t-number>
</ng-template>