<div [ngClass]="['button', className]"
     [style.top.px]="top"
     [style.left.px]="left">

    <i [class.active]="active"></i>
    <em *ngIf="arrowButton"
        [ngStyle]="{'transform': arrowTransforms}"></em>

    <span [class.absolute]="isAbsolute">
        <ng-content></ng-content>
    </span>
</div>