<div class="topBorder">
    <span class="l mr-10 w-40"></span>
    <span class="l mr-10"></span>
    <span class="l mr-10"></span>
    <span class="l mr-10"></span>
    <span class="l mr-10"></span>
    <span class="r ml-10 w-40"></span>
    <span class="r ml-10"></span>
    <span class="r ml-10"></span>
    <span class="r ml-10"></span>
    <span class="r ml-10"></span>
</div>
<h1>{{title}}</h1>
<div class="view">
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
</div>
<div class="view l">
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <em></em>
    <t-tile [tile]="filled"></t-tile>
    <p></p>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
    <div class="clear"></div>
    <t-tile [tile]="filled"></t-tile>
</div>