<div class="keyboard"
     [style.margin-top.px]='filling'>
    <t-button className="blue btn-md"
              [active]="keyboardService.up()"
              [arrowButton]="ArrowButton.UP"
              (mousedown)="mouseDown($event, 'Up')"
              (mouseup)="mouseUp($event, 'Up')"
              (touchstart)="mouseDown($event, 'Up')"
              (touchend)="mouseUp($event, 'Up')"
              [top]="0"
              [left]="374"
              [isAbsolute]="true">
        Rotation
    </t-button>
    <t-button className="blue btn-md"
              [active]="keyboardService.down()"
              [arrowButton]="ArrowButton.DOWN"
              (mousedown)="mouseDown($event, 'Down')"
              (mouseup)="mouseUp($event, 'Down')"
              (touchstart)="mouseDown($event, 'Down')"
              (touchend)="mouseUp($event, 'Down')"
              [top]="180"
              [left]="374">
        Down
    </t-button>
    <t-button className="blue btn-md"
              [active]="keyboardService.left()"
              [arrowButton]="ArrowButton.LEFT"
              (mousedown)="mouseDown($event, 'Left')"
              (mouseup)="mouseUp($event, 'Left')"
              (touchstart)="mouseDown($event, 'Left')"
              (touchend)="mouseUp($event, 'Left')"
              [top]="90"
              [left]="284">
        Left
    </t-button>
    <t-button className="blue btn-md"
              [active]="keyboardService.right()"
              [arrowButton]="ArrowButton.RIGHT"
              (mousedown)="mouseDown($event, 'Right')"
              (mouseup)="mouseUp($event, 'Right')"
              (touchstart)="mouseDown($event, 'Right')"
              (touchend)="mouseUp($event, 'Right')"
              [top]="90"
              [left]="464">
        Right
    </t-button>
    <t-button className="blue btn-md"
              [active]="keyboardService.hold()"
              (mousedown)="mouseDown($event, 'Hold')"
              (mouseup)="mouseUp($event, 'Hold')"
              (touchstart)="mouseDown($event, 'Hold')"
              (touchend)="mouseUp($event, 'Hold')"
              [top]="100"
              [left]="0">
        Hold (C)
    </t-button>
    <t-button className="blue btn-lg"
              [active]="keyboardService.drop()"
              (mousedown)="mouseDown($event, 'Space')"
              (mouseup)="mouseUp($event, 'Space')"
              (touchstart)="mouseDown($event, 'Space')"
              (touchend)="mouseUp($event, 'Space')"
              [top]="100"
              [left]="110">
        Drop (SPACE)
    </t-button>
    <t-button className="red btn-sm"
              [active]="keyboardService.reset()"
              (mousedown)="mouseDown($event, 'Reset')"
              (mouseup)="mouseUp($event, 'Reset')"
              (touchstart)="mouseDown($event, 'Reset')"
              (touchend)="mouseUp($event, 'Reset')"
              [top]="0"
              [left]="196">
        Reset (R)
    </t-button>
    <t-button className="green btn-sm"
              [active]="keyboardService.sound()"
              (mousedown)="mouseDown($event, 'Sound')"
              (mouseup)="mouseUp($event, 'Sound')"
              (touchstart)="mouseDown($event, 'Sound')"
              (touchend)="mouseUp($event, 'Sound')"
              [top]="0"
              [left]="106">
        Sound (S)
    </t-button>
    <t-button className="green btn-sm"
              [active]="keyboardService.pause()"
              (mousedown)="mouseDown($event, 'Pause')"
              (mouseup)="mouseUp($event, 'Pause')"
              (touchstart)="mouseDown($event, 'Pause')"
              (touchend)="mouseUp($event, 'Pause')"
              [top]="0"
              [left]="16">
              {{ pauseButtonLabel()}} (P)
    </t-button>
</div>